import loadable from "@loadable/component";
import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { HelmetProvider } from "react-helmet-async"; // import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import pMinDelay from "p-min-delay";
import routes from "./routes";
import { darkTheme, GlobalStyles, lightTheme } from "./styles";
import { client, darkModeVar, isLoggedInVar } from "./apollo";
import Loading from "./components/common/Loading";

const loadableConfog = {
  fallback: <Loading />,
};

const Login = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'Login' */ "./components/screens/Login"),
      1000
    ),
  loadableConfog
);
const Layout = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'Layout' */ "./components/Layout"),
      1000
    ),
  loadableConfog
);
const DashBoard = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'DashBoard' */ "./components/DashBoard"),
      1000
    ),
  loadableConfog
);
const BulletinBoard = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'BulletinBoard' */ "./components/BulletinBoard"
      ),
      1000
    ),
  loadableConfog
);
const Companies = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'Companies' */ "./components/Companies"),
      1000
    ),
  loadableConfog
);
const Managers = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'Managers' */ "./components/Managers"),
      1000
    ),
  loadableConfog
);
const Deals = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'Deals' */ "./components/Deals"),
      1000
    ),
  loadableConfog
);
const Quotes = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'Quotes' */ "./components/Quotes"),
      1000
    ),
  loadableConfog
);
const Config = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'Config' */ "./components/Config"),
      1000
    ),
  loadableConfog
);
const CreateQuoteForm = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'CreateQuoteForm' */ "./components/Quotes/CreateQuoteForm"
      ),
      1000
    ),
  loadableConfog
);
const DetailQuoteForm = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'DetailQuoteForm' */ "./components/Quotes/DetailQuoteForm"
      ),
      1000
    ),
  loadableConfog
);
const Activities = loadable(
  () =>
    pMinDelay(
      import(/* webpackChunkName: 'Activities' */ "./components/Activities"),
      1000
    ),
  loadableConfog
);
const DetailDeal = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'DetailDeal' */ "./components/Deals/DetailDeal"
      ),
      1000
    ),
  loadableConfog
);
const DetailCompany = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'DetailCompany' */ "./components/Companies/DetailCompany"
      ),
      1000
    ),
  loadableConfog
);
const DetailManager = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'DetailManager' */ "./components/Managers/DetailManager"
      ),
      1000
    ),
  loadableConfog
);
const DetailActivity = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'DetailActivity' */ "./components/Activities/DetailActivity"
      ),
      1000
    ),
  loadableConfog
);
const CreateCompany = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'CreateCompany' */ "./components/Companies/CreateCompany"
      ),
      1000
    ),
  loadableConfog
);
const CreateManager = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'CreateManager' */ "./components/Managers/CreateManager"
      ),
      1000
    ),
  loadableConfog
);
const CreateDeal = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'CreateDeal' */ "./components/Deals/CreateDeal"
      ),
      1000
    ),
  loadableConfog
);
const CreateActivity = loadable(
  () =>
    pMinDelay(
      import(
        /* webpackChunkName: 'CreateActivity' */ "./components/Activities/CreateActivity"
      ),
      1000
    ),
  loadableConfog
);
const Logs = loadable(
  () =>
    pMinDelay(import(/* webpackChunkName: 'Logs' */ "./components/Logs"), 1000),
  loadableConfog
);

function App() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const darkMode = useReactiveVar(darkModeVar);

  return (
    <ApolloProvider client={client}>
      <>
        <HelmetProvider>
          <ToastContainer />
          <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <GlobalStyles />
            <Router>
              {isLoggedIn ? (
                <Switch>
                  <Layout>
                    <Route exact path={routes.dashboard}>
                      <DashBoard />
                    </Route>
                    <Route exact path={routes.bulletinBoard}>
                      <BulletinBoard />
                    </Route>
                    <Route path={routes.companies}>
                      <Companies />
                    </Route>
                    <Route path={routes.managers}>
                      <Managers />
                    </Route>
                    <Route path={routes.deals}>
                      <Deals />
                    </Route>
                    <Route path={routes.quotes}>
                      <Quotes />
                    </Route>
                    <Route path={routes.config}>
                      <Config />
                    </Route>
                    <Route path={routes.createQuote}>
                      <CreateQuoteForm />
                    </Route>
                    <Route path={routes.activities}>
                      <Activities />
                    </Route>
                    <Route path={routes.detailQuote + "/:id"}>
                      <DetailQuoteForm />
                    </Route>
                    <Route path={routes.detailDeal + "/:id"}>
                      <DetailDeal />
                    </Route>
                    <Route path={routes.detailCompany + "/:id"}>
                      <DetailCompany />
                    </Route>
                    <Route path={routes.detailManager + "/:id"}>
                      <DetailManager />
                    </Route>
                    <Route path={routes.detailActivity + "/:id"}>
                      <DetailActivity />
                    </Route>
                    <Route path={routes.createCompany}>
                      <CreateCompany />
                    </Route>
                    <Route path={routes.createManager}>
                      <CreateManager />
                    </Route>
                    <Route path={routes.createDeal}>
                      <CreateDeal />
                    </Route>
                    <Route path={routes.createActivity}>
                      <CreateActivity />
                    </Route>
                    <Route path={routes.logs}>
                      <Logs />
                    </Route>
                    {/* <Redirect from="*" to="/" /> */}
                  </Layout>
                </Switch>
              ) : (
                <Login />
              )}
            </Router>
          </ThemeProvider>
        </HelmetProvider>
      </>
    </ApolloProvider>
  );
}

export default App;
