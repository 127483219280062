import reset from "styled-reset";
import { createGlobalStyle, DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  accent: "#0095f6",
  borderColor: "#E6E8EB",
  shadow: "rgba(145, 158, 171, 0.24)",
  divider: "#C4CDD5",
  grey: {
    oneHundred: "#F9FAFB",
    twoHundred: "#F4F6F8",
    threeHundred: "#DFE3E8",
    fourHundred: "#C4CDD5",
    fiveHundred: "#919EAB",
    sixHundred: "#637381",
    sevenHundred: "#454F5B",
    eightHundred: "#212B36",
    nineHundred: "#161C24",
    percent8: "rgba(145, 158, 171, 0.08)",
    percent12: "rgba(145, 158, 171, 0.12)",
    percent16: "rgba(145, 158, 171, 0.16)",
    percent24: "rgba(145, 158, 171, 0.24)",
    percent32: "rgba(145, 158, 171, 0.32)",
    percent48: "rgba(145, 158, 171, 0.48)",
  },
  common: {
    white: "#fff",
    whitePercent48: "rgba(255, 255, 255, 0.48)",
    black: "#000",
    blackPercent48: "rgba(0, 0, 0, 0.48)",
  },
  actionState: {
    active: "#637381",
    hover: "rgba(145, 158, 171, 0.08)",
    selected: "rgba(145, 158, 171, 0.16)",
    disabled: "rgba(145, 158, 171, 0.8)",
    disabledBackground: "rgba(145, 158, 171, 0.24)",
    focus: "rgba(145, 158, 171, 0.24)",
  },
  backgroundColor: { papaer: "#FFF", default: "#FFF", neutral: "#F4F6F8" },
  textColor: {
    primary: "#212B36",
    secondary: "#637381",
    disabled: "#919EAB",
  },
  primary: {
    lighter: "#C8FACD",
    light: "#5BE584",
    main: "#00AB55",
    dark: "#007B55",
    darker: "#005249",
    percent8: "rgba(0, 171, 85, 0.08)",
    percent12: "rgba(0, 171, 85, 0.12)",
    percent16: "rgba(0, 171, 85, 0.16)",
    percent24: "rgba(0, 171, 85, 0.24)",
    percent32: "rgba(0, 171, 85, 0.32)",
    percent48: "rgba(0, 171, 85, 0.48)",
  },
  info: {
    lighter: "#D0F2FF",
    light: "#74CAFF",
    main: "#1890FF",
    dark: "#0C53B7",
    darker: "#04297A",
    percent8: "rgba(24, 144, 255, 0.08)",
    percent12: "rgba(24, 144, 255, 0.12)",
    percent16: "rgba(24, 144, 255, 0.16)",
    percent24: "rgba(24, 144, 255, 0.24)",
    percent32: "rgba(24, 144, 255, 0.32)",
    percent48: "rgba(24, 144, 255, 0.48)",
  },
  success: {
    lighter: "#E9FCD4",
    light: "#AAF27F",
    main: "#54D62C",
    dark: "#229A16",
    darker: "#08660D",
    percent8: "rgba(84, 214, 44, 0.08)",
    percent12: "rgba(84, 214, 44, 0.12)",
    percent16: "rgba(84, 214, 44, 0.16)",
    percent24: "rgba(84, 214, 44, 0.24)",
    percent32: "rgba(84, 214, 44, 0.32)",
    percent48: "rgba(84, 214, 44, 0.48)",
  },
  warning: {
    lighter: "#FFF7CD",
    light: "#FFE16A",
    main: "#FFC107",
    dark: "#B78103",
    darker: "#7A4F01",
    percent8: "rgba(255, 193, 7, 0.08)",
    percent12: "rgba(255, 193, 7, 0.12)",
    percent16: "rgba(255, 193, 7, 0.16)",
    percent24: "rgba(255, 193, 7, 0.24)",
    percent32: "rgba(255, 193, 7, 0.32)",
    percent48: "rgba(255, 193, 7, 0.48)",
  },
  error: {
    lighter: "#FFE7D9",
    light: "#FFA48D",
    main: "#FF4842",
    dark: "#B72136",
    darker: "#7A0C2E",
    percent8: "rgba(255, 72, 66, 0.08)",
    percent12: "rgba(255, 72, 66, 0.12)",
    percent16: "rgba(255, 72, 66, 0.16)",
    percent24: "rgba(255, 72, 66, 0.24)",
    percent32: "rgba(255, 72, 66, 0.32)",
    percent48: "rgba(255, 72, 66, 0.48)",
  },
};

export const darkTheme: DefaultTheme = {
  shadow: "rgba(145, 158, 171, 0.24)",
  divider: "rgba(145, 158, 171, 0.24)",
  grey: {
    oneHundred: "#F9FAFB",
    twoHundred: "#F4F6F8",
    threeHundred: "#DFE3E8",
    fourHundred: "#C4CDD5",
    fiveHundred: "#919EAB",
    sixHundred: "#637381",
    sevenHundred: "#454F5B",
    eightHundred: "#212B36",
    nineHundred: "#161C24",
    percent8: "rgba(145, 158, 171, 0.08)",
    percent12: "rgba(145, 158, 171, 0.12)",
    percent16: "rgba(145, 158, 171, 0.16)",
    percent24: "rgba(145, 158, 171, 0.24)",
    percent32: "rgba(145, 158, 171, 0.32)",
    percent48: "rgba(145, 158, 171, 0.48)",
  },
  common: {
    white: "#fff",
    whitePercent48: "rgba(255, 255, 255, 0.48)",
    black: "#000",
    blackPercent48: "rgba(0, 0, 0, 0.48)",
  },
  actionState: {
    active: "#919EAB",
    hover: "rgba(145, 158, 171, 0.08)",
    selected: "rgba(145, 158, 171, 0.16)",
    disabled: "rgba(145, 158, 171, 0.8)",
    disabledBackground: "rgba(145, 158, 171, 0.24)",
    focus: "rgba(145, 158, 171, 0.24)",
  },
  backgroundColor: {
    papaer: "#212B36",
    default: "#161C24",
    neutral: "#F4F6F8",
  },
  textColor: {
    primary: "#fff",
    secondary: "#919EAB",
    disabled: "#637381",
  },
  primary: {
    lighter: "#C8FACD",
    light: "#5BE584",
    main: "#00AB55",
    dark: "#007B55",
    darker: "#005249",
    percent8: "rgba(0, 171, 85, 0.08)",
    percent12: "rgba(0, 171, 85, 0.12)",
    percent16: "rgba(0, 171, 85, 0.16)",
    percent24: "rgba(0, 171, 85, 0.24)",
    percent32: "rgba(0, 171, 85, 0.32)",
    percent48: "rgba(0, 171, 85, 0.48)",
  },
  info: {
    lighter: "#D0F2FF",
    light: "#74CAFF",
    main: "#1890FF",
    dark: "#0C53B7",
    darker: "#04297A",
    percent8: "rgba(24, 144, 255, 0.08)",
    percent12: "rgba(24, 144, 255, 0.12)",
    percent16: "rgba(24, 144, 255, 0.16)",
    percent24: "rgba(24, 144, 255, 0.24)",
    percent32: "rgba(24, 144, 255, 0.32)",
    percent48: "rgba(24, 144, 255, 0.48)",
  },
  success: {
    lighter: "#E9FCD4",
    light: "#AAF27F",
    main: "#54D62C",
    dark: "#229A16",
    darker: "#08660D",
    percent8: "rgba(84, 214, 44, 0.08)",
    percent12: "rgba(84, 214, 44, 0.12)",
    percent16: "rgba(84, 214, 44, 0.16)",
    percent24: "rgba(84, 214, 44, 0.24)",
    percent32: "rgba(84, 214, 44, 0.32)",
    percent48: "rgba(84, 214, 44, 0.48)",
  },
  warning: {
    lighter: "#FFF7CD",
    light: "#FFE16A",
    main: "#FFC107",
    dark: "#B78103",
    darker: "#7A4F01",
    percent8: "rgba(255, 193, 7, 0.08)",
    percent12: "rgba(255, 193, 7, 0.12)",
    percent16: "rgba(255, 193, 7, 0.16)",
    percent24: "rgba(255, 193, 7, 0.24)",
    percent32: "rgba(255, 193, 7, 0.32)",
    percent48: "rgba(255, 193, 7, 0.48)",
  },
  error: {
    lighter: "#FFE7D9",
    light: "#FFA48D",
    main: "#FF4842",
    dark: "#B72136",
    darker: "#7A0C2E",
    percent8: "rgba(255, 72, 66, 0.08)",
    percent12: "rgba(255, 72, 66, 0.12)",
    percent16: "rgba(255, 72, 66, 0.16)",
    percent24: "rgba(255, 72, 66, 0.24)",
    percent32: "rgba(255, 72, 66, 0.32)",
    percent48: "rgba(255, 72, 66, 0.48)",
  },
};

export const GlobalStyles = createGlobalStyle`
  ${reset}
  
  input {
    all:unset;
  }
  * {
    box-sizing:border-box;
    ::-webkit-scrollbar {	width: 8px;height: 8px;background: none; } /* 스크롤 바 */
    ::-webkit-scrollbar-track { background-color:none; } /* 스크롤 바 밑의 배경 */
    ::-webkit-scrollbar-thumb { background: #ddd; border-radius: 4px; } /* 실질적 스크롤 바 */
    ::-webkit-scrollbar-thumb:hover { background: #404040; } /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */
    ::-webkit-scrollbar-thumb:active { background: #808080; } /* 실질적 스크롤 바를 클릭할 때 */
    ::-webkit-scrollbar-button { display: none; } /* 스크롤 바 상 하단 버튼 */
  }
  
  html {
    font-size: 62.5%;
  }
  body {
    font-family: 'Noto Sans KR';
    background-color:${({ theme }) => theme.backgroundColor.papaer};
    color:${({ theme }) => theme.textColor.primary};
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
        > path {
          margin: 12px;
        }
      }
    }
    fieldset {
      height: 61px;
    }
    .Mui-error {

    }
    .MuiInput-underline:after {
      border-color: ${({ theme }) => theme.primary.main};
    }
    .MuiInputLabel-root {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
      background-color: white;
      padding: 0 5px 0 5px;
      letter-spacing: 1px;
      text-align: left;
      color: ${({ theme }) => theme.textColor.disabled};
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${({ theme }) => theme.primary.main} !important;
    }

    
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.grey.percent32};
    }
    .Mui-focused {
      > fieldset {
        border-color: ${({ theme }) => theme.primary.main} !important;
      }
    }
    .Mui-error {
      > fieldset {
        border-color: ${({ theme }) => theme.error.main} !important;
      }
    }
  
    .MuiInputBase-input {
      font-size: 16px;
      line-height: 16px;
    }
    .MuiMenu-paper {
      margin-top:40px;
      .MuiMenu-list {
        /* display: flex; */
        transform-origin: 0px;
      }
    }
    .tui-full-calendar-layout{
      .use-calendar-company-name {
        color: #00AB55;
      }
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;
