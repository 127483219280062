import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import "./utils/fonts/NotoSansKR-normal";
// import "./utils/fonts/NotoSansKR-bold";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const msg = "%cATNP PARTNERS";
const css =
  "font-size: 5em; color: #00AB55; font-family: 'Noto Sans KR'; font-weight: 600;";
console.log(msg, css);

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
