// export interface IRoutes {

// }

const routes = {
  dashboard: "/",
  companies: "/companies",
  managers: "/managers",
  deals: "/deals",
  quotes: "/quotes",
  createQuote: "/create-quote",
  detailQuote: "/detail-quote",
  activities: "/activities",
  // admins: "/admins",
  config: "/config",
  detailDeal: "/detail-deal",
  detailCompany: "/detail-company",
  detailManager: "/detail-manager",
  detailActivity: "/detail-activity",
  // 추후 추가 예정
  bulletinBoard: "/bulletin-board",
  contracts: "/contracts",
  detailContract: "/detail-contract",
  bonds: "/bonds",
  detailBond: "/detail-bond",
  // 신규
  createCompany: "/create-company",
  createManager: "/create-manager",
  createDeal: "/create-deal",
  createActivity: "/create-activity",
  // log
  logs: "/logs",
};
export default routes;
