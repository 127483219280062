import React from "react";
import styled from "styled-components";

const Loading = () => {
  return (
    <Container2>
      <div className="loader">
        <div className="box"></div>
        <div className="box2"></div>
        <div className="ball"></div>
      </div>
    </Container2>
    // <Container>
    //   <div className="loader">
    //     <svg viewBox="0 0 80 80">
    //       <circle id="test" cx="40" cy="40" r="32"></circle>
    //     </svg>
    //   </div>
    //   <div className="loader triangle">
    //     <svg viewBox="0 0 86 80">
    //       <polygon points="43 8 79 72 7 72"></polygon>
    //     </svg>
    //   </div>
    //   <div className="loader">
    //     <svg viewBox="0 0 80 80">
    //       <rect x="8" y="8" width="64" height="64"></rect>
    //     </svg>
    //   </div>
    // </Container>
  );
};

const Container2 = styled.div`
  width: 100vw;
  min-height: 100vh;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    background-image: linear-gradient(
      90deg,
      rgba(200, 250, 205, 1) 0%,
      rgba(0, 171, 85, 1) 35%,
      rgba(0, 82, 73, 1) 100%
    );
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .ball {
      width: 10px;
      height: 10px;
      background-color: rgba(0, 0, 0, 0.23);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      animation-name: ballAnimation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      transition: all 0.5s;
      z-index: 9999;
    }
    .box2 {
      background-image: linear-gradient(
        90deg,
        rgba(200, 250, 205, 1) 0%,
        rgba(0, 171, 85, 1) 35%,
        rgba(0, 82, 73, 1) 100%
      );
      position: absolute;
      top: calc(50% - 0px);
      left: calc(50% - 0px);
      width: 50px;
      height: 50px;
      box-shadow: inset 0 0 10px rgba(0, 90, 46, 0.71);
      z-index: 1;
      animation-name: boxAnimation1;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      transition: all 0.5s;
    }
    .box {
      background-image: linear-gradient(
        90deg,
        rgba(200, 250, 205, 1) 0%,
        rgba(0, 171, 85, 1) 35%,
        rgba(0, 82, 73, 1) 100%
      );
      position: absolute;
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      width: 50px;
      height: 50px;
      box-shadow: inset 0 0 10px rgba(0, 90, 46, 0.71);
      z-index: 1;
      animation-name: boxAnimation2;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      transition: all 0.5s;
    }
  }

  @keyframes boxAnimation1 {
    0%,
    50% {
      top: calc(50% - 0px);
      left: calc(50% - 0px);
    }
    60%,
    90% {
      top: calc(50% - 50px);
      left: calc(50% - 50px);
    }
    100% {
      top: calc(50% - 0px);
      left: calc(50% - 0px);
    }
  }
  @keyframes boxAnimation2 {
    0%,
    50% {
      top: calc(50% - 50px);
      left: calc(50% - 50px);
    }
    60%,
    90% {
      top: calc(50% - 0px);
      left: calc(50% - 0px);
    }
    100% {
      top: calc(50% - 50px);
      left: calc(50% - 50px);
    }
  }

  @keyframes ballAnimation {
    0% {
      margin-top: 0px;
      margin-left: 0px;
      border-radius: 0;
    }
    10% {
      margin-top: 0px;
      margin-left: 0px;
      border-radius: 0;
    }
    20% {
      margin-top: -100px;
      margin-left: 0px;
      border-radius: 40%;
    }
    30% {
      margin-top: -100px;
      margin-left: -100px;
      border-radius: 100%;
    }
    40% {
      margin-top: 0px;
      margin-left: -100px;
      border-radius: 100%;
    }
    50% {
      margin-top: 0px;
      margin-left: 0px;
      border-radius: 0%;
    }
    60% {
      margin-top: 0px;
      margin-left: 0px;
      border-radius: 0%;
    }
    70% {
      margin-top: 100px;
      margin-left: 0px;
      border-radius: 40%;
    }
    80% {
      margin-top: 100px;
      margin-left: 100px;
      border-radius: 80%;
    }
    90% {
      margin-top: 0px;
      margin-left: 100px;
      border-radius: 100%;
    }
    100% {
      margin-top: 0px;
      margin-left: 0px;
      border-radius: 0%;
    }
  }
`;

// const Container = styled.div`
//   width: 100vw;
//   height: 100vh;
//   z-index: 1000;
//   position: absolute;
//   top: 0;
//   left: 0;
//   background-color: rgba(255, 255, 255, 0.8);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .loader {
//     --path: #2f3545;
//     --dot: #00ab55;
//     --duration: 3s;
//     width: 44px;
//     height: 44px;
//     position: relative;
//     &:before {
//       content: "";
//       width: 6px;
//       height: 6px;
//       border-radius: 50%;
//       position: absolute;
//       display: block;
//       background: var(--dot);
//       top: 37px;
//       left: 19px;
//       transform: translate(-18px, -18px);
//       animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
//         infinite;
//     }
//     svg {
//       display: block;
//       width: 100%;
//       height: 100%;
//       rect,
//       polygon,
//       circle {
//         fill: none;
//         stroke: var(--path);
//         stroke-width: 10px;
//         stroke-linejoin: round;
//         stroke-linecap: round;
//       }
//       polygon {
//         stroke-dasharray: 145 (221 - 145) 145 (221 - 145);
//         stroke-dashoffset: 0;
//         animation: pathTriangle var(--duration)
//           cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
//       }
//       rect {
//         stroke-dasharray: (256 / 4 * 3) (256 / 4) (256 / 4 * 3) (256 / 4);
//         stroke-dashoffset: 0;
//         animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
//       }
//       circle {
//         stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
//         stroke-dashoffset: 75;
//         animation: pathCircle var(--duration)
//           cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
//       }
//     }
//     &.triangle {
//       width: 48px;
//       &:before {
//         left: 21px;
//         transform: translate(-10px, -18px);
//         animation: dotTriangle var(--duration)
//           cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
//       }
//     }
//   }

//   @keyframes pathTriangle {
//     33% {
//       stroke-dashoffset: 74;
//     }
//     66% {
//       stroke-dashoffset: 147;
//     }
//     100% {
//       stroke-dashoffset: 221;
//     }
//   }

//   @keyframes dotTriangle {
//     33% {
//       transform: translate(0, 0);
//     }
//     66% {
//       transform: translate(10px, -18px);
//     }
//     100% {
//       transform: translate(-10px, -18px);
//     }
//   }

//   @keyframes pathRect {
//     25% {
//       stroke-dashoffset: 64;
//     }
//     50% {
//       stroke-dashoffset: 128;
//     }
//     75% {
//       stroke-dashoffset: 192;
//     }
//     100% {
//       stroke-dashoffset: 256;
//     }
//   }

//   @keyframes dotRect {
//     25% {
//       transform: translate(0, 0);
//     }
//     50% {
//       transform: translate(18px, -18px);
//     }
//     75% {
//       transform: translate(0, -36px);
//     }
//     100% {
//       transform: translate(-18px, -18px);
//     }
//   }

//   @keyframes pathCircle {
//     25% {
//       stroke-dashoffset: 125;
//     }
//     50% {
//       stroke-dashoffset: 175;
//     }
//     75% {
//       stroke-dashoffset: 225;
//     }
//     100% {
//       stroke-dashoffset: 275;
//     }
//   }

//   .loader {
//     display: inline-block;
//     margin: 0 16px;
//   }

//   html {
//     -webkit-font-smoothing: antialiased;
//   }

//   * {
//     box-sizing: border-box;
//     &:before,
//     &:after {
//       box-sizing: border-box;
//     }
//   }
// `;

export default React.memo(Loading);
